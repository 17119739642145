import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Root from './sections/Root/Root';
import { AnimatePresence } from 'framer-motion';

// Use React.lazy to dynamically import the components
const HomePage = lazy(() => import('./sections/Home'));
const BlogPage = lazy(() => import('./sections/Blog'));
const StoryDetail = lazy(() => import('./sections/Blog/Story/StoryDetail'));
const ConfirmationPage = lazy(() => import('./sections/Email/ConfirmationPage'));
const RevokePage = lazy(() => import('./sections/Email/RevokePage'));
const Contact = lazy(() => import('./sections/Contact'));
const AboutUs = lazy(() => import('./sections/About'));

const App = () => {
  return (
    <Root>
      {/* Wrap the routes with Suspense and provide a fallback for loading state */}
      <Suspense fallback={<div>Loading...</div>}>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="stories/:id" element={<StoryDetail />} />
            <Route path="readers/confirm/:token" element={<ConfirmationPage />} />
            <Route path="readers/revoke/:token" element={<RevokePage />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<AboutUs />} />
            {/* ... other routes */}
          </Routes>
        </AnimatePresence>
      </Suspense>
    </Root>
  );
};

export default App;

import React from 'react';
import './styles.css'
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="footer">

          <Link to={'/'} className="logo">
                <img src="/SB-Chime-FF.png" alt="SB Chime Logo" />
            </Link>
            <div className="links">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/blog">Blog</Link>
              {/* <Link to="/community">Community</Link> */}
              <Link to="/contact">Contact</Link>
              {/* Add more links as needed */}
            </div>
            <div className="social-media">
                {/* Replace # with your actual social media links */}
                <a href="https://www.instagram.com/sbchime" target="_blank" rel="noopener noreferrer">Instagram</a>
            </div>
            <div className="contact">
                <p>Email: kev@sbchime.com</p>
            </div>
        </footer>
    );
}

export default Footer;

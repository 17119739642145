import { FC, ReactNode } from "react";
import Footer from "./Footer";
import { ScrollRestoration } from "react-router-dom";
import Header from "./Header";
import './styles.css'

interface RootProps {
  children?: ReactNode
}

const Root: FC<RootProps> = ({children}) => {
    return (
        <div className="site-container">
            <Header />
            <div className="main-content">
                
                {children}
            </div>
            <Footer />
            <ScrollRestoration getKey={(location) => {
                        return location.pathname;
                    }} /> 
        </div>
    );
}

export default Root;

import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './styles.css';
import { Link } from 'react-router-dom';

const mobileMenuVariants = {
  closed: {
    scaleX: 0,
    scaleY: 0,
    opacity: 0,
    originX: 1, // Anchor transformation to the right
    originY: 0, // Anchor transformation to the top
    transition: {
      scaleX: { duration: 0.2 },
      scaleY: { duration: 0.2 },
      opacity: { duration: 0.2 }
    },
    display: 'none'
  },
  open: {
    scaleX: 1,
    scaleY: 1,
    opacity: 1,
    transition: {
      scaleX: { duration: 0.3, delay: 0.1 },
      scaleY: { duration: 0.3, delay: 0.1 },
      opacity: { duration: 0.2, delay: 0.1 }
    },
    display: 'block'
  }
};

const containerVariants = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { type: 'spring', stiffness: 300, damping: 20 },
      opacity: { duration: 0.3 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { type: 'spring', stiffness: 300, damping: 20 },
      opacity: { duration: 0.2 }
    }
  }
};


const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const handleNavigation = (path: string) => setIsOpen(false);
  const handleKeyDown = (event: React.KeyboardEvent, path: string) => event.key === 'Enter' && handleNavigation(path);
  const paths = ['/', '/about', '/blog', '/contact'];
  // Generate the mobile menu links with unique keys
  const mobileMenuLinks = paths.map((path) => {
    let str = path.substring(1);
    return (
      <motion.li key={`mobile-${path}`} variants={itemVariants} whileHover={{ scale: 1.1, color: "#FFF" }} whileTap={{ scale: 0.95 }} onKeyDown={(e) => handleKeyDown(e, path)} tabIndex={0}>
        <Link to={path} onClick={() => handleNavigation(path)} style={{ position: 'relative' }}>
          {str ? (str[0].toUpperCase() + str.slice(1)) : 'Home'}
        </Link>
      </motion.li>
    );
  });

  const desktopMenuLinks = paths.map((path) => {
    let str = path.substring(1);
    return (
      <li key={`desktop-${path}`}><Link to={path} onClick={() => handleNavigation(path)}>{str? (str[0].toUpperCase() + str.slice(1)) : 'Home'}</Link></li>

    )
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures this effect runs only on mount and unmount


  return (
 <header style={{ position: 'relative' }}> {/* Ensure the header has a relative position */}
      <Link className="header-logo" to="/"><img src='/SB-Chime-FF.png' alt="logo" /></Link>
        <motion.button whileTap={{ scale: 0.97 }} onClick={toggleMenu} className="menu-toggle" aria-label={isOpen ? "Close menu" : "Open menu"} aria-expanded={isOpen}>
          <span className={`hamburger-icon ${isOpen ? 'open' : ''}`}></span>
        </motion.button>
        {isOpen && (
           <motion.div
           initial="closed"
           animate={isOpen ? "open" : "closed"}
           exit="closed"
           variants={mobileMenuVariants}
           className="mobile-menu-container"
         >
           <motion.nav
             className="mobile-menu"
             aria-label="Main navigation"
           >
             <motion.ul
               variants={containerVariants}
               initial="closed"
               animate={isOpen ? "open" : "closed"}
             >
               {mobileMenuLinks}
             </motion.ul>
           </motion.nav>
         </motion.div>
         
        )}
      <nav className="desktop-nav"><ul>{desktopMenuLinks}</ul></nav>
    </header>
   
  );
};

export default Header;

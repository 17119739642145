import { FC } from "react";

const Logo: FC = () => {
    return (
        <div>
            <img src="/Infinity.gif" alt="Infinity"/>
        </div>
    );
}

export default Logo;
